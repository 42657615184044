import Icon from 'components/Icon'
import {useTimeout} from 'hooks/useTimeout'
import styles from './Toast.module.scss'

function Toast({toastStyle, children, onClose, timeout = 15}) {
  let icon, inlineStyles
  switch (toastStyle) {
    case 'warning':
      icon = <Icon id="warning" />
      inlineStyles = {
        backgroundColor: 'var(--color-warning)',
        color: 'var(--color-gray-800)',
      }
      break
    case 'error':
      icon = <Icon id="error" />
      inlineStyles = {
        backgroundColor: 'var(--color-error)',
      }
      break
    case 'success':
      icon = <Icon id="success" />
      inlineStyles = {
        backgroundColor: 'var(--color-success)',
        color: 'var(--color-gray-800)',
      }
      break
    default:
      icon = <Icon id="info" />
      inlineStyles = {
        backgroundColor: 'var(--color-primary)',
      }
  }

  useTimeout(() => {
    if (typeof onClose === 'function') onClose()
  }, timeout * 1000)

  return (
    <>
      <div className={styles.toast} role="alert">
        <div className={styles.iconWrapper} style={inlineStyles}>
          {icon}
        </div>
        <div className={styles.messageSpan}>{children}</div>
      </div>
    </>
  )
}

export default Toast
