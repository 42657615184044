const localStorageKey = '__vgtrack_user_token__'

async function client(
  endpoint,
  {data, token, headers: customHeaders, ...customConfig} = {},
) {
  const config = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      ...customHeaders,
    },
    ...customConfig,
  }

  return fetch(endpoint, config).then(async (response) => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

function getUser() {
  const userString = localStorage.getItem(localStorageKey)
  if (!userString) return null
  return JSON.parse(userString)
}

function login({email, password}) {
  const data = {user: {email, password}}
  return client(`/api/users/sign_in`, {data}).then(handleUserResponse)
}

function logout() {
  localStorage.removeItem(localStorageKey)
}

function handleUserResponse(user) {
  localStorage.setItem(localStorageKey, JSON.stringify(user))
  return user
}

function register({username, email, password}) {
  const data = {user: {email, username, password}}
  return client(`/api/users`, {data}).then(handleUserResponse)
}

export {login, logout, register, getUser, localStorageKey}
