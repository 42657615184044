import {Disclosure} from '@headlessui/react'
import Icon from 'components/Icon'
import Logo from 'components/Logo'
import styles from './ErrorComponent.module.scss'

function ErrorComponent({error}) {
  return (
    <div className={styles.wrapper}>
      <Logo />
      <div role="alert">
        <p>Something went wrong, please try again in a moment.</p>

        <Disclosure>
          <div className={styles.errorDisclosure}>
            <Disclosure.Button>
              View error details <Icon id="chevron" size={18} />
            </Disclosure.Button>
            <Disclosure.Panel>
              <pre>{error.message}</pre>
            </Disclosure.Panel>
          </div>
        </Disclosure>
      </div>
    </div>
  )
}

export default ErrorComponent
