import {Popover} from '@headlessui/react'
import VisuallyHidden from 'components/VisuallyHidden'
import {useState} from 'react'
import {usePopper} from 'react-popper'
import {Link} from 'react-router-dom'
import Icon from '../Icon'
import styles from './UserDropdown.module.scss'

function UserDropdown({user, onLogout}) {
  const isLoggedIn = Boolean(user)
  const [popperRefElement, setPopperRefElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const {styles: popperStyles, attributes} = usePopper(
    popperRefElement,
    popperElement,
    {
      placement: 'bottom-end',
    },
  )

  return (
    <Popover>
      <Popover.Button
        className={styles.userDropdownButton}
        ref={setPopperRefElement}
      >
        <Icon id="user" size={24}>
          <VisuallyHidden>User menu</VisuallyHidden>
        </Icon>
      </Popover.Button>
      <Popover.Panel
        className={styles.userDropdownList}
        ref={setPopperElement}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        {isLoggedIn ? (
          <>
            <div className={styles.username}>
              <Icon id="user" />
              {user.username}
            </div>
            <div className={styles.separator} />
            <button className={styles.logoutButton} onClick={onLogout}>
              <Icon id="log-out" size={18} />
              Log out
            </button>
          </>
        ) : (
          <>
            <Link to="/sign-up">
              <Icon id="sign-up" size={18} />
              Sign up
            </Link>
            <Link to="/login">
              <Icon id="log-in" size={18} />
              Log in
            </Link>
          </>
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default UserDropdown
