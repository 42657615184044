import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query'
import {useAuth, useClient} from 'hooks/useAuth'

function useNotifications() {
  const client = useClient()
  const {user} = useAuth()

  const query = useQuery(['notifications', user?.username], () => {
    if (user) {
      return client(`/api/notifications`).then((data) => data)
    } else {
      return []
    }
  })

  return query
}

function useNotificationMutation(uuid) {
  const client = useClient()
  const queryClient = useQueryClient()
  const {user} = useAuth()

  return useMutation(
    (read = true) => {
      return client(`/api/notifications/${uuid}`, {
        method: 'PUT',
        data: {read},
      })
    },
    {
      onSuccess: (_data, read) => {
        queryClient.setQueryData(
          ['notifications', user?.username],
          (oldData) => {
            return oldData.map((notification) => {
              if (notification.uuid === uuid) return {...notification, read}
              else return notification
            })
          },
        )
      },
    },
  )
}

function useDeleteNotificationMutation(uuid) {
  const client = useClient()
  const queryClient = useQueryClient()
  const {user} = useAuth()

  return useMutation(
    () => {
      return client(`/api/notifications/${uuid}`, {
        method: 'DELETE',
      })
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(
          ['notifications', user?.username],
          (oldData) => {
            return oldData.filter((notification) => notification.uuid !== uuid)
          },
        )
      },
    },
  )
}

export default useNotifications
export {useNotificationMutation, useDeleteNotificationMutation}
