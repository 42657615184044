import GameSearch from 'components/GameSearch'
import Icon from 'components/Icon'
import Logo from 'components/Logo'
import MobileMenu from 'components/MobileMenu'
import NotificationDropdown from 'components/NotificationDropdown'
import UserDropdown from 'components/UserDropdown'
import VisuallyHidden from 'components/VisuallyHidden'
import {useAuth} from 'hooks/useAuth'
import PropTypes from 'prop-types'
import {useState} from 'react'
import {Link, useMatch} from 'react-router-dom'
import styles from './Header.module.scss'

const NavLink = (props) => {
  const active = useMatch(props.to)
  const activeStyles = {
    backgroundColor: `var(--color-primary)`,
    borderRadius: '4px',
    border: '2px solid var(--color-primary)',
  }
  return <Link {...props} style={active ? activeStyles : {}} />
}

const Header = ({showNav = true}) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const {user, logout} = useAuth()

  return (
    <>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <Link to="/">
            <VisuallyHidden>Home</VisuallyHidden>
            <Logo width={48} />
          </Link>
          {showNav && (
            <>
              <GameSearch />
              <NavLink className={styles.link} to="/backlog">
                Backlog
              </NavLink>
              <NavLink className={styles.link} to="/tracked">
                Tracked Games
              </NavLink>
              <NavLink className={styles.link} to="/upcoming">
                Upcoming Games
              </NavLink>
            </>
          )}
        </nav>
        <div className={styles.side} />
        {showNav && (
          <>
            <div className={styles.profileActions}>
              {!user && (
                <>
                  <Link className={styles.link} to="/login">
                    Log in
                  </Link>
                </>
              )}
              {user && <NotificationDropdown />}
              <UserDropdown user={user} onLogout={() => logout()} />
            </div>
            <nav className={styles.mobileNav}>
              <Link to="/">
                <VisuallyHidden>Home</VisuallyHidden>
                <Logo width={32} />
              </Link>
              <GameSearch />
              <div className={styles.side} />
              <Icon
                id="menu"
                size={32}
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              />
            </nav>
          </>
        )}
      </header>
      <MobileMenu
        isOpen={showMobileMenu}
        onDismiss={() => setShowMobileMenu(false)}
      />
    </>
  )
}
Header.propTypes = {
  showNav: PropTypes.bool,
}

export default Header
