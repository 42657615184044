import {Popover} from '@headlessui/react'
import Icon from 'components/Icon'
import Loading from 'components/Loading'
import NotificationIcon from 'components/NotificationIcon'
import {useAuth} from 'hooks/useAuth'
import {useCreateBacklogEntry} from 'hooks/useBacklogEntries'
import useNotifications, {
  useDeleteNotificationMutation,
  useNotificationMutation,
} from 'hooks/useNotifications'
import {DateTime} from 'luxon'
import {Fragment, useMemo, useState} from 'react'
import {usePopper} from 'react-popper'
import {Link} from 'react-router-dom'
import {igdbImageUrl} from 'utils'
import styles from './NotificationDropdown.module.scss'

function Notification({
  closePanel,
  uuid,
  title,
  game_slug,
  first_release_date,
  igdb_cover_image_id,
  read,
  gameId,
}) {
  const notificationReadMutation = useNotificationMutation(uuid)
  const deleteNotificationMutation = useDeleteNotificationMutation(uuid)
  const createBacklogEntry = useCreateBacklogEntry()
  const [popperRefElement, setPopperRefElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const popper = usePopper(popperRefElement, popperElement, {
    placement: 'bottom',
  })

  return (
    <li className={styles.notificationContainer}>
      <Popover as={Fragment}>
        <Popover.Button className={styles.moreButton}>
          <span ref={setPopperRefElement}>
            <Icon id="more" size={22} />
          </span>
        </Popover.Button>
        <Popover.Panel
          ref={setPopperElement}
          style={popper.styles.popper}
          {...popper.attributes.popper}
        >
          {({close}) => (
            <ul className={styles.moreMenu}>
              <li>
                <button
                  onClick={() => {
                    notificationReadMutation.mutate(!read)
                    close()
                  }}
                >
                  Mark as {read ? 'unread' : 'read'}
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    createBacklogEntry.mutate(gameId)
                    notificationReadMutation.mutate(true)
                    close()
                  }}
                >
                  Add to backlog
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    deleteNotificationMutation.mutate()
                    close()
                  }}
                >
                  Dismiss
                </button>
              </li>
            </ul>
          )}
        </Popover.Panel>
      </Popover>
      <Link
        to={`/games/${game_slug}`}
        onClick={() => {
          notificationReadMutation.mutate(true)
          closePanel()
        }}
        className={styles.notification}
      >
        <div className={styles.notificationContent}>
          <p>
            <span className={styles.title}>{title}</span> has been released!
          </p>
          <p className={styles.releasedAgo}>
            {DateTime.fromISO(first_release_date).toRelativeCalendar()}
          </p>
        </div>
        <img
          className={styles.coverImage}
          src={igdbImageUrl(igdb_cover_image_id, 't_cover_small')}
          srcSet={igdbImageUrl(igdb_cover_image_id, 't_cover_small_2x')}
          width={45}
          alt=""
        />
        <div>
          <div
            className={`${styles.unreadIndicator} ${
              read ? styles.read : styles.unread
            }`}
          />
        </div>
      </Link>
    </li>
  )
}

function NotificationDropdown() {
  const {user} = useAuth()
  const {data: notifications, isLoading} = useNotifications()
  const unreadCount = useMemo(() => {
    return Math.min(
      notifications?.filter((notification) => notification.read === false)
        .length,
      99,
    )
  }, [notifications])
  const [popperRefElement, setPopperRefElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const popper = usePopper(popperRefElement, popperElement, {
    placement: 'bottom-end',
  })

  if (!user) return

  return (
    <Popover>
      <Popover.Button
        className={styles.btnOpenDropdown}
        ref={setPopperRefElement}
      >
        <NotificationIcon unreadCount={unreadCount} />
      </Popover.Button>
      <Popover.Panel
        className={styles.dropdownPanel}
        ref={setPopperElement}
        style={popper.styles.popper}
        {...popper.attributes.popper}
      >
        {({close}) => (
          <>
            <div className={styles.notificationsHeader}>Notifications</div>
            {isLoading && <Loading />}
            {notifications.length === 0 && (
              <p className={styles.emptyMessage}>You have no notifications.</p>
            )}
            {notifications?.length > 0 && (
              <ul className={styles.notificationList}>
                {notifications?.map((notification) => (
                  <Notification
                    key={notification.uuid}
                    gameId={notification.game_id}
                    closePanel={close}
                    {...notification}
                  />
                ))}
              </ul>
            )}
          </>
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default NotificationDropdown
