import PropTypes from 'prop-types'
import {
  FiAlertCircle,
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiBell,
  FiCalendar,
  FiCheckCircle,
  FiChevronDown,
  FiEdit,
  FiGrid,
  FiInfo,
  FiList,
  FiLogIn,
  FiLogOut,
  FiMenu,
  FiMoreVertical,
  FiPlusCircle,
  FiSearch,
  FiSettings,
  FiTarget,
  FiTrash2,
  FiUser,
  FiUserPlus,
  FiX,
  FiXCircle,
} from 'react-icons/fi'
import styles from './Icon.module.scss'

const icons = {
  menu: FiMenu,
  close: FiX,
  error: FiXCircle,
  info: FiInfo,
  warning: FiAlertCircle,
  success: FiCheckCircle,
  user: FiUser,
  'log-out': FiLogOut,
  'log-in': FiLogIn,
  'sign-up': FiUserPlus,
  track: FiTarget,
  untrack: FiXCircle,
  'arrow-up': FiArrowUp,
  'arrow-down': FiArrowDown,
  'arrow-left': FiArrowLeft,
  'arrow-right': FiArrowRight,
  search: FiSearch,
  chevron: FiChevronDown,
  grid: FiGrid,
  list: FiList,
  notification: FiBell,
  more: FiMoreVertical,
  trash: FiTrash2,
  settings: FiSettings,
  edit: FiEdit,
  'plus-circle': FiPlusCircle,
  calendar: FiCalendar,
}

const Icon = ({id, color, size, strokeWidth, children, ...delegated}) => {
  const Component = icons[id]

  if (!Component) {
    throw new Error(`No icon found for ID: ${id}`)
  }

  return (
    <div className={styles.icon} style={{strokeWidth}} {...delegated}>
      <Component color={color} size={size} />
      {children}
    </div>
  )
}
Icon.propTypes = {
  id: PropTypes.oneOf(Object.keys(icons)).isRequired,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  children: PropTypes.element,
}

const IconButton = ({
  id,
  color,
  size,
  strokeWidth,
  children,
  onClick,
  ...delegated
}) => {
  const iconProps = {id, color, size, strokeWidth, children}
  return (
    <button className={styles.button} onClick={onClick}>
      <Icon {...iconProps} {...delegated} />
    </button>
  )
}
IconButton.propTypes = {
  id: PropTypes.oneOf(Object.keys(icons)).isRequired,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  children: PropTypes.element,
  onClick: PropTypes.func,
}

export default Icon
export {IconButton}
