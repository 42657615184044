import App, {AppProviders} from 'App'
import React from 'react'
import {createRoot} from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'
import 'styles/globals.css'

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
