import {Combobox} from '@headlessui/react'
import clsx from 'clsx'
import Icon from 'components/Icon'
import Loading from 'components/Loading'
import VisuallyHidden from 'components/VisuallyHidden'
import {useSearch} from 'hooks/useSearch'
import {debounce, isEmpty, truncate} from 'lodash'
import {Fragment, useMemo, useState} from 'react'
import {usePopper} from 'react-popper'
import {useNavigate} from 'react-router-dom'
import {displayDateFromReleaseInfo, igdbImageUrl} from 'utils'
import styles from './GameSearch.module.scss'

function GameSearch() {
  const [query, setQuery] = useState()
  const [selected, setSelected] = useState()
  const results = useSearch({query})
  const navigate = useNavigate()
  const [popperRefElement, setPopperRefElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const {styles: popperStyles, attributes} = usePopper(
    popperRefElement,
    popperElement,
  )

  function handleSelect(value) {
    setSelected('')
    if (value === 'search') {
      navigate(`/search?q=${query}`)
    }
    if (value.slug) {
      navigate(`/games/${value.slug}`)
    }
  }

  const searchResults = useMemo(
    () =>
      results.data?.slice(0, 5).map((game) => (
        <Combobox.Option key={game.uuid} value={game} as={Fragment}>
          {({active}) => (
            <li
              className={clsx({
                [styles.resultWrapper]: true,
                [styles.activeResult]: active,
              })}
            >
              <img
                alt=""
                className={styles.coverImage}
                src={igdbImageUrl(game.idgb_cover_image_id, 't_cover_small')}
                srcSet={igdbImageUrl(
                  game.igdb_cover_image_id,
                  't_cover_small_2x',
                )}
                width={60}
                height={72}
              />
              <h4>{truncate(game.title, {length: 60, omission: '…'})}</h4>
              <p>{displayDateFromReleaseInfo(game.release_info)}</p>
            </li>
          )}
        </Combobox.Option>
      )),
    [results],
  )

  const searchMore = (
    <Combobox.Option value="search" as={Fragment}>
      {({active}) => (
        <li
          className={clsx({[styles.searchMore]: true, [styles.active]: active})}
        >
          Search for "{query}" {active}
        </li>
      )}
    </Combobox.Option>
  )

  return (
    <Combobox
      value={selected}
      onChange={handleSelect}
      defaultValue={searchMore}
    >
      <div className={styles.inputWrapper}>
        <Icon id="search" size={32}>
          <VisuallyHidden>Search</VisuallyHidden>
        </Icon>
        <Combobox.Input
          className={styles.searchInput}
          displayValue={(game) => game?.title}
          onChange={debounce((event) => setQuery(event.target.value), 300)}
          placeholder="Search"
          autoComplete="off"
          ref={setPopperRefElement}
        />
      </div>
      {!isEmpty(query) ? (
        <Combobox.Options
          className={styles.resultsList}
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {results.isLoading && <Loading />}
          {results.isSuccess && isEmpty(results.data) && (
            <li style={{marginLeft: 4}}>Sorry, no games found.</li>
          )}
          {results.isSuccess && !isEmpty(results.data) && searchMore}
          {searchResults}
        </Combobox.Options>
      ) : null}
    </Combobox>
  )
}

export default GameSearch
