import {ReactComponent as VGTrackLogo} from 'logo.svg'
import styles from './Logo.module.scss'

function Logo({text, width = 96}) {
  return (
    <div className={styles.logo}>
      <VGTrackLogo width={width} />
      <span>{text}</span>
    </div>
  )
}

export default Logo
