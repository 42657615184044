import {capitalize} from 'lodash'

export const humanDate = (date, dateStyle = 'long') =>
  new Intl.DateTimeFormat(navigator.language, {dateStyle}).format(
    Date.parse(date),
  )

export const igdbImageUrl = (hash, style = 't_cover_big') =>
  `https://images.igdb.com/igdb/image/upload/${style}/${hash}.png`

export const displayDateFromReleaseInfo = (releaseInfo, dateStyle = 'long') => {
  const {category, release_date, human} = releaseInfo

  if (category === 'yyyy_mmmm_dd') {
    return humanDate(release_date, dateStyle)
  } else {
    return human
  }
}

export const formatGameCategory = (category) => {
  switch (category) {
    case 'dlc_addon':
      return 'DLC'
    case 'standalone_expansion':
      return 'Standalone'
    case 'expanded_game':
      return 'Expanded Game'
    default:
      return capitalize(category)
  }
}
