import '@fontsource-variable/lexend'
import '@fontsource-variable/outfit'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import ErrorComponent from 'components/ErrorComponent'
import Header from 'components/Header'
import Loading from 'components/Loading'
import {AuthProvider} from 'hooks/useAuth'
import {ToastList, ToastProvider} from 'hooks/useToast'
import {Suspense, lazy} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom'
import styles from './App.module.scss'

const Home = lazy(() => import('pages/Home'))
const TrackedGames = lazy(() => import('pages/TrackedGames'))
const UpcomingGames = lazy(() => import('pages/UpcomingGames'))
const Search = lazy(() => import('pages/Search'))
const Login = lazy(() => import('pages/Login'))
const Register = lazy(() => import('pages/Register'))
const ForgotPassword = lazy(() => import('pages/ForgotPassword'))
const ResetPassword = lazy(() => import('pages/ResetPassword'))
const Game = lazy(() => import('pages/Game'))
const Backlog = lazy(() => import('pages/Backlog'))

function AppProviders({children}) {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AuthProvider>
        <ToastProvider>
          <HelmetProvider>
            <BrowserRouter>{children}</BrowserRouter>
          </HelmetProvider>
        </ToastProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <Helmet titleTemplate="%s · VGTrack" defaultTitle="VGTrack" />
      <Suspense
        fallback={
          <div>
            <Loading />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/tracked" element={<TrackedGames />} />
            <Route path="/upcoming" element={<UpcomingGames />} />
            <Route path="/search" element={<Search />} />
            <Route path="/games/:gameId/*" element={<Game />} />
            <Route path="/backlog" element={<Backlog />} />
          </Route>
          <Route element={<Layout showNav={false} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  )
}

function Layout({showNav = true}) {
  return (
    <>
      <Header showNav={showNav} />
      <main className={styles.main}>
        <Outlet />
      </main>
      <ToastList />
    </>
  )
}

export default App
export {AppProviders}
