import {useQuery} from '@tanstack/react-query'
import {useClient} from 'hooks/useAuth'

function useSearch({query}) {
  const client = useClient()

  return useQuery(['search', query], () => {
    if (!query) {
      return []
    } else {
      return client(`/api/search?q=${query}`).then((data) => data.games)
    }
  })
}

export {useSearch}
