import {getUser, logout} from 'utils/auth'

export default async function client(
  endpoint,
  {data, params, token, headers: customHeaders, ...customConfig} = {},
) {
  const queryString = new URLSearchParams(params).toString()
  const url = params ? `${endpoint}?${queryString}` : endpoint
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : null,
      'Content-Type': data ? 'application/json' : 'plain/text',
      ...customHeaders,
    },
    ...customConfig,
  }

  return fetch(url, config).then(async (response) => {
    if (response.status === 401 && getUser() !== null) {
      logout()
      window.location.assign(window.location)
      return Promise.reject({status: 401, message: 'Please re-authenticate'})
    } else if (response.status === 401) {
      return Promise.reject({status: 401, message: 'Please re-authenticate'})
    }

    if (response.status === 204) {
      return
    }

    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}
